
export default {
    emits: ['update:visible'],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object
    },
    data() {
        return {
            url: null,
            columns: [{
                    key: 'index',
                    title: this.$t('行号'),
                    width: 50,
                    dataIndex: 'row_index'
                },
                {
                    title: this.$t('日志类型'),
                    dataIndex: 'log_type',
                    width: 100,
                    sorter: true,
                    slots: {
                        customRender: 'log_type'
                    }
                },
                {
                    title: this.$t('消息内容'),
                    dataIndex: 'content',
                    sorter: true
                }
            ]
        };
    },
    watch: {
        "data.id": {
            handler(val) {
                this.url = '/user/order_import_task/logs/' + val;
            }
        }
    },
    methods: {
        /* 搜索 */
        reload() {
            this.$refs.table.reload({
                page: 1
            });
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        onClose() {
            this.updateVisible(false);
        }
    },
    setup() {
        return { };
    }
};
